/* html,
body {
    font-family: "Open Sans", serif;
} */
/* .hero.is-info {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("https://unsplash.it/1200/900?random") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
} */
.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hero .subtitle {
  padding: 3rem 0;
  line-height: 1.5;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.header-image {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.49), rgba(0, 0, 0, 0.89)),
        url("https://images.unsplash.com/1/iphone-4-closeup.jpg?dpr=1&auto=format&crop=middle&fit=crop&w=2000&h=700&q=80"); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.89),rgba(0, 0, 0, 0.49)); */
  background-color: black;
}
.is-outlined {
  background-color: transparent;
  color: #fff;
}
.hero-cta {
  padding: 30px 0;
}
.panel-block.section p {
  font-size: 17px;
  line-height: 1.4;
  color: #95a5a6;
}
.section.main {
  background-color: #f0f0f0;
}
.icon-block {
  font-size: 5em;
}

.hero a {
  /* color: #3273dc !important; */
}
/* div.box {
    text-align: left;
} */

.navbar-brand h1 {
  font-size: 200%;
}
h1.brand-title code {
  background-color: unset;
  color: white;
}
